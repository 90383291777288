import styled from "styled-components";

export const Page = styled.div`
    display: ${props => props.center && 'flex'};
    align-items: ${props => props.center && 'center'};
    justify-content: ${props => props.center && 'center'};
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: #ECEFF1;
`

export const Header = styled.span`
    display: flex;
    width: 100%;
    height: 12%;
`

export const Box = styled.div`
    display: flex;
    background-color: white;
    border-radius: 5px;
    height: fit-content;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #424242;
    padding: 40px 30px;
    margin-bottom: 20px;
`

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.align};
    justify-content: ${props => props.justify};
    background-color: white;
    border-radius: 5px;
    height: 100%;
    max-height: 100%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #424242;
    padding: 15px;
`

export const VerticalBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    height: fit-content;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #424242;
    padding: 40px 30px;
    margin-bottom: 20px;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.align && props.align};
    justify-content: ${props => props.justify && props.justify};
    margin-left: ${props => props.marginLeft && props.marginLeft};
    margin-right: ${props => props.marginRight && props.marginRight};
    width: ${props => (typeof props.width === 'string' ? props.width : typeof props.width === 'number' ? props.width+'\%' : 100+'\%')};
    height: ${props => props.height && props.height}%;
    border-bottom: ${props => props.borderBtm && '1px solid #ccc'};
    padding-bottom: ${props => props.paddingBtm && props.paddingBtm+'px'};
    padding-left: ${props => props.paddingLeft && props.paddingLeft+'px'};
`

export const Row = styled.span`
    display: flex;
    align-items: ${props => props.align ? props.align : 'none'};
    justify-content: ${props => props.justify ? props.justify : 'none'};
    width: ${props => props.width || '100%'};
    height: ${props => (typeof props.height === 'string' ? props.height : typeof props.height === 'number' ? props.height+'\%' : 100+'\%')};
    padding-bottom: ${props => props.bottom && props.bottom}px;
    padding-left: ${props => props.left && props.left}px;
    border-bottom-style: ${props => props.borderBtmStyle && props.borderBtmStyle};
    border-bottom-width: ${props => props.borderBtmWidth && props.borderBtmWidth+'px'};
    border-bottom-color: ${props => props.borderBtmColor && props.borderBtmColor};
    background-color: ${props => props.color && props.color};
`

export const Space = styled.div`
    height: ${props => props.height && props.height}px;
    width: ${props => props.width && props.width}px;
`

export const Spacer = styled.div`
    margin-top: ${props => props.height && props.height}%;
    margin-left: ${props => props.width && props.width}%;
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border-width: ${props => props.borderWidth}px;
    border-style: solid;
    border-color: ${props => props.borderColor};
    border-radius: ${props => props.borderRadius}px;
    color: ${props => props.color ? props.color : '#424242'};
    background-color: ${props => props.backgroundColor};
    cursor: pointer;
`

export const Text = styled.h1`
    font-size: ${props => props.size ? props.size : 12}px;
    font-weight: ${props => props.weight ? props.weight : 400};
    color: ${props => props.color ? props.color : '#424242'};
    text-align: ${props => props.align && props.align};
    text-decoration: ${props => props.decoration};
    cursor: ${props => props.pointer && 'pointer'};
    white-space: wrap;

    &:hover{
        color: ${props => props.hover && '#1565C0'};
    }
`

export const P = styled.p`
    font-size: ${props => props.size ? props.size : 12}px;
    font-weight: ${props => props.weight ? props.weight : 400};
    color: ${props => props.color ? props.color : '#424242'};
    text-align: ${props => props.align && props.align};
    margin: ${props => props.margin && props.margin}px;

    strong{
        font-weight: 800;
        color: #888;
    }
`

export const Section = styled.div`
    display: flex;
    flex-direction: ${props => props.direction};
    align-items: ${props => props.align ? props.align : 'none'};
    padding: ${props => props.padding}px;
    justify-content: ${props => props.justify ? props.justify : 'none'};
    width: ${props => props.width && props.width}%;
    height: ${props => props.height && props.height}%;
    background-color: ${props => props.color};
    position: relative;
`

export const Icon = styled.div`
    display: flex;
    position: ${props => props.position || undefined};
    top: ${props => props.top || undefined};
    bottom: ${props => props.bottom || undefined};
    left: ${props => props.left || undefined};
    right: ${props => props.right || undefined};
    align-items: center;
    margin: ${props => props.margin ? props.margin : '5px'};
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    padding-top: ${props => props.top && props.top}px;
    padding-bottom: ${props => props.bottom && props.bottom}px;
    padding-left: ${props => props.left && props.left}px;
    padding-right: ${props => props.right && props.right}px;
    cursor: ${props => props.pointer && 'pointer'};
    
    svg{
        color: ${props => props.color ? props.color : '#424242'};
        font-size: ${props => props.size && props.size}px;
    }

    path{
        stroke: ${props => props.stroke};
    }
`

export const Avatar = styled.label`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    background-image: url(${props => props.img});
    background-color: #ccc;
    background-position: center;
    background-size: contain;
    cursor: pointer;
`

export const Circle = styled.div`
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    border-radius: 50%;
    background-color: ${props => props.color};
    margin: 5px;
`