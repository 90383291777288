import { useState, useEffect, useContext } from "react";
import { Line, Form, Input, Submit, Spacer, TextLink } from '../../layout'
import { Text, Icon, Row, P, Column } from '../../layout/Layout'
import { PulseLoader } from 'react-spinners'
import { PrintingContext } from "../../contexts/PrintingProvider";
import { HiOutlinePrinter } from 'react-icons/hi2'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BsCheck2Circle } from 'react-icons/bs'

function PrinterStatus({status}){

    const { printTest } = useContext(PrintingContext)

    if(!status) return (
        <Row align={'center'} width={'fit-content'}>
            <Icon size={60} color="#00BFA5">
                <HiOutlinePrinter/>
            </Icon>
            <PulseLoader color="#263238" size={12}/>
            <Icon size={36} color="#FF3D00">
                <AiOutlineCloseCircle/>
            </Icon>
        </Row>
    )

    return (
        <Column align={'center'}>
            <Icon size={48} color="#00C853">
                <BsCheck2Circle/>
            </Icon>
            <Text color={'#37474F'} size={18} weight={600}>Conectada!</Text>
            <Spacer height={1}/>
            <TextLink onClick={() => printTest()}>
                Imprimir Teste
            </TextLink>
        </Column>
    )
}

export default function PrinterConnectBox({onSuccess}){

    const { printerConnected, requestPrinter } = useContext(PrintingContext)

    return(
        <>
            <Text size={18} weight={700} color={'#00BFA5'}>
                Conectar Impressora
            </Text>
            <Spacer height={1}/>
            <P align={'center'} size={12} weight={500} color={'#aaa'}>
                Antes de prosseguir é necessário <strong>conectar a impressora</strong> que fará a <strong>impressão das senhas</strong>.
            </P>
            <Line height={25} width={40}/>
            <PrinterStatus status={printerConnected}/>
            {
                printerConnected ?
                <Submit onClick={() => onSuccess()}>
                    Prosseguir
                </Submit>
                :
                <Submit onClick={() => requestPrinter()}>
                    Conectar
                </Submit>
            }
        </>
    )
}