import styled from "styled-components"

const Image = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-image: url(${props => props.bgUrl});
    background-size: cover;
    background-position: center;
`

const Gradient = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(${props => props.gradientAngle + 'deg'}, ${props => props.gradientColors[0]}, ${props => props.gradientColors[1]});
`

const Solid = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: ${props => props.color};
`

export default function Background({UI, children}){

    const { bgType: type, bgColor, bgUrl, bgWebUrl, gradientColors, gradientAngle} = UI
 
    if(type === 'color') return (
        <Solid color={bgColor}>
            {children}
        </Solid>
    )

    else if(type === 'gradient') return (
        <Gradient gradientAngle={gradientAngle} gradientColors={gradientColors}>
            {children}
        </Gradient>
    )

    else if(type === 'image') return (
        <Image bgUrl={bgWebUrl ? bgWebUrl : bgUrl}>
            {children}
        </Image>
    )
}