import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { DataProvider } from './contexts/DataProvider'
import Home from './pages/home'
import IES from './pages/ies'
import { PrintingProvider } from './contexts/PrintingProvider'

function App() {

  return (
    <DataProvider>
      <PrintingProvider>
        <Router>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='ies' element={<IES/>}/>
          </Routes>
        </Router>
      </PrintingProvider>
    </DataProvider>
  )
}

export default App;
