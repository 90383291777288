import { useState } from "react";
import { Box, Page, RightContainer } from "./styles";
import LeftSection from "./LeftSection";
import { useNavigate } from 'react-router-dom'
import LoginBox from './LoginBox'
import PrinterConnectBox from "./PrinterConnectBox";

export default function Home(){

    const [step, setStep] = useState('login')

    const navigate = useNavigate()

    return(
        <Page>
            <LeftSection/>
            <RightContainer>
                <Box>
                    {
                        step === 'login' ?
                        <LoginBox onSuccess={() => setStep('printer')}/>
                        :
                        step === 'printer' &&
                        <PrinterConnectBox onSuccess={() => navigate('/ies')}/>
                    }
                </Box>
            </RightContainer>
        </Page>
    )
}