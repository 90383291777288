import styled from "styled-components"
import { Icon } from "../../../layout/Layout"
import { FaArrowLeft } from 'react-icons/fa'

const Button = styled.button`
    display: flex;
    align-items: center;
    position: absolute;
    top: 3rem;
    left: 3rem;
    align-items: center;
    justify-content: center;
    height: 8%;
    width: 14%;
    background-color: rgba(38,50,53,0.2);
    border: none;
    outline: none;
    border-radius: 3rem;
    font-size: 2rem;
    font-weight: 600;
    color: white;
    cursor: pointer;
`

export default function BackButton({onClick}){


    return (
        <Button onClick={onClick}>
            <Icon color={'white'} size={28}>
                <FaArrowLeft/>
            </Icon>
            Voltar
        </Button>
    )
}