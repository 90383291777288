import { Container, Title, Background } from './styles'
import bg from '../../../img/bg1.png'
import logo from '../../../img/logo_main.png'

export default function LeftSection(){


    return(
        <Container>
            <Title>
                <img src={logo}/>
                <h1><strong>Sistema Inteligente</strong><br/> de gestão de filas e atendimento</h1>
            </Title>
            <Background src={bg}/>
        </Container>
    )
}