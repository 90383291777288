import { useState, useEffect, useContext } from "react";
import { Line, Form, Input, Submit, Spacer } from '../../layout'
import { Text, Icon, Row, P } from '../../layout/Layout'
import { HiOutlineKey } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import { DataContext } from "../../contexts/DataProvider";

export default function LoginBox({onSuccess}){

    const { authenticate } = useContext(DataContext)

    const [shortId, setShortId] = useState('')
    
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        setError(null)
    },[shortId])

    const handleIdInput = (value) => {
        if(isNaN(value) || value.length > 8) return
        setShortId(value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if(shortId.length !== 8) {
            setError('O ID deve conter 8 dígitos numéricos')
            return
        }
        
       doLogin()
    }

    const doLogin = async () => {
        setLoading(true)

        const status = await authenticate(shortId)

        if(status === 200) return onSuccess()
        else if(status === 404){
            setLoading(false)
            setError('ID Inválido. Tente novamente.')
            return
        }
    }


    return(
        <>
            <Text size={18} weight={700} color={'#00BFA5'}>
                Interface de Emissão de Senhas
            </Text>
            <Spacer height={1}/>
            <P align={'center'} size={12} weight={500} color={'#aaa'}>
                Digite o <strong>ID do Terminal</strong> que deseja abrir e <strong>clique em entrar</strong> para iniciar a <strong>Interface de Emissão de Senhas</strong>
            </P>
            <Line height={25} width={40}/>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Row align={'center'} justify={'center'} height={15}>
                    <Icon size={22} color={'#00BFA5'} width={25}>
                        <HiOutlineKey/>
                    </Icon>
                    <Input type={'text'} inputmode={'numeric'} name={'shortId'} value={shortId} placeholder='Insira o ID do Terminal' onChange={(e) => handleIdInput(e.target.value)}/>
                </Row>
                <Submit type='submit'>
                    {
                        loading ? 
                        <PulseLoader color={'white'} loading={true} size={8}/>
                        :
                        'Entrar'
                    }
                </Submit>
            </Form>
            {
                error &&
                <Text weight={600} color={'red'}>{error}</Text>
            }
        </>
    )
}