import api from './api'

export const create = async (route, data, token) => {
    return await api.post(route, data, {headers: {authorization: 'Bearer ' + token}})
}

export const update = async (route, id, data, token) => {
    return await api.put(`${route}/${id}`, data, {headers: {authorization: 'Bearer ' + token}})
}

export const auth = async (shortId) => {
    return await api.post(`/directterminal/${shortId}`)
}