import { Br, Cut, QRCode, Printer, Text } from 'react-thermal-printer'

export const printTestModel = (
    <Printer type="epson" width={42} characterSet="pc860_portuguese">
        <Text align="center" size={{ width: 2, height: 2 }}>Teste</Text>
        <Text align="center" size={{ width: 2, height: 2 }}>de Impressão</Text>
        <Br />
        <Text align="center" size={{ width: 1, height: 1 }}>Está tudo certo com a impressão deste equipamento</Text>
        <Br />
        <Text align="center" size={{ width: 1, height: 1 }}>Para mais informações</Text>
        <Text align="center" size={{ width: 1, height: 1 }}>visite nosso site:</Text>
        <QRCode align="center" cellSize={6} content="https://www.rhomi.com.br" />
        <Br />
        <Text align="center" size={{ width: 1, height: 1 }}>www.rhomi.com.br</Text>
        <Br />
        <Cut />
    </Printer>
    );