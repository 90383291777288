import { Br, Cut, QRCode, Printer, Text, Image } from 'react-thermal-printer'

export default function PasswordModel(call, printLayout){

    const {
        logo_url, show_logo,
        show_title, title,
        show_slogan, slogan, 
        show_time,  show_pw_type,
        show_text, line1, line2,
        show_QR, QR_title, QR_code,
        show_thanks, thanks_text,
        invert
    } = printLayout

    const { password, created_at, type, subplace } = call

    console.log(printLayout)
    
    const timeString = (unix) => {

        const time = new Date(unix)
    
        const day = time.getDate()
        const month = time.getMonth() + 1
        const year = time.getFullYear()
    
        const hour = time.getHours()
        const min = time.getMinutes()
        const sec = time.getSeconds()
    
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year} ${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}` 
    }

    return(

        <Printer type="epson" width={42} characterSet="pc860_portuguese">
            {/* {
                (show_logo && logo_url) &&
                <Image align='center' src={logo}/>
            } */}
            {
                show_title && title &&
                <Text align="center" size={{ width: 2, height: 1 }}>{title}</Text>
            }
            {
                show_slogan && slogan &&
                <Text align="center" size={{ width: 1, height: 1 }}>{slogan}</Text>
            }
            {
                ((show_title && title) || (show_slogan && slogan)) &&
                <Br/>
            }
            {
                show_time &&
                <Text align="center" size={{ width: 1, height: 1 }}>{timeString(created_at)}</Text>
            }
            {
                show_pw_type &&
                <>
                    <Text align="center" size={{ width: 1, height: 1 }}>{subplace}</Text>
                    <Text align="center" size={{ width: 1, height: 1 }}>{`Tipo: ${type}`}</Text>
                    <Br/>
                </>
            }
            <Text align="center" size={{ width: 1, height: 1 }}>Senha:</Text>
            <Text bold={true} invert={invert} align="center" size={{ width: 2, height: 2 }}>{` ${password} `}</Text>
            <Br/>
            {
                show_text && (line1 || line2) &&
                <>
                    <Text align="center" size={{ width: 1, height: 1 }}>{line1}</Text>
                    <Text align="center" size={{ width: 1, height: 1 }}>{line2}</Text>
                    <Br/>
                </>
            }
            {
                (show_QR && QR_code) &&
                <>
                    <Text align="center" size={{ width: 1, height: 1 }}>{QR_title}</Text>
                    <QRCode align='center' cellSize={6} content={QR_code}/>
                    <Br/>
                </>
            }
            {
                (show_thanks && thanks_text) &&
                <>
                    <Text align="center" size={{ width: 1, height: 1 }}>{thanks_text}</Text>
                    <Br/>
                </>
            }
            <Cut />
        </Printer>
    )
}