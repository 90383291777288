import { MoonLoader } from "react-spinners"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 40vh;
    width: 40vw;
    border-radius: 5rem;
    background-color: rgba(38,50,53,0.2);

    h1{
        font-size: 2rem;
        font-weight: 600;
        color: #455A64;
        margin-top: 3rem;
    }
`

export default function PrintingIndicator({show}){


    if(show) return (
        <Container>
            <MoonLoader size={60} color="#455A64"/>
            <h1>Imprimindo...</h1>
        </Container>
    )
}