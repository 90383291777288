import { createContext, useState, useContext } from "react";
import { auth } from "../api/queries";
//import { StorageContext } from "./StorageProvider";

export const DataContext = createContext()

export const DataProvider = ({children}) => {

    //const { storeNewId } = useContext(StorageContext)

    const [token, setToken] = useState(null)
    const [UI, setUI] = useState(null)
    const [printLayout, setPrintLayout] = useState(null)
    const [services, setServices] = useState(null)
    // const [confirmation, setConfirmation] = useState(null)
    // const [autoAproach, setAutoAproach] = useState(null)
    const [terminal, setTerminal] = useState(null)

    const authenticate = async (shortId) => {

        try {
            const res = await auth(shortId)

            //await storeNewId({name: res.data.terminal.name, shortId})

            setToken(res.data.token)
            setUI(res.data.interface)
            setPrintLayout(res.data.printlayout)
            setServices(res.data.services)
            //setAutoAproach(res.data.terminal.auto_aproach)
            //setConfirmation(res.data.terminal.confirmation)
            setTerminal(res.data.terminal)

            return res.status
        } catch (error) {
            return error.response ? error.response.status : 400       
        }
    }

    return(
        <DataContext.Provider value={{token, UI, printLayout, services, terminal, authenticate }}>
            {children}
        </DataContext.Provider>
    )
}