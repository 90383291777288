import { createContext, useContext, useEffect, useRef, useState } from "react";
import { render } from 'react-thermal-printer'
import { printTestModel } from "../content/PrintTestModel";
import PasswordModel from "../content/PasswordModel";
import { DataContext } from "./DataProvider";

export const PrintingContext = createContext()

export const PrintingProvider = ({children}) => {

    const { printLayout } = useContext(DataContext)

    const logo = useRef(null)

    const printer = useRef(null)
    const [printerConnected, setPrinterConnected] = useState(false)

    // useEffect(() => {

    //     const prefetchLogo = () => {
    //         logo.current = new Image()
    //         logo.current.src = 'https://uploads-rhomi.s3.sa-east-1.amazonaws.com/01740222f77772b4996ab9ff3aceb67c-rhomi_jpg_300x100.jpg'     
    //     }

    //     prefetchLogo()
    //     console.log(logo.current)
    // },[])

    const requestPrinter = async () => {
        printer.current = await navigator.usb.requestDevice({ filters: []})

        await printer.current.open();
        await printer.current.selectConfiguration(1);
        await printer.current.claimInterface(0);
        await printer.current.controlTransferOut({
            requestType: "class",
            recipient: "interface",
            request: 0x22,
            value: 0x01,
            index: 0x00
        });

        setPrinterConnected(printer.current.opened)
    }

    const printTest = async () => {

        const data = await render(printTestModel);

        await printer.current.transferOut(3, data)
    }

    const printPassword = async (call) => {
        console.log(printLayout)
        const data = await render(PasswordModel(call, printLayout))
     
        await printer.current.transferOut(3, data)
    }


    return(
        <PrintingContext.Provider value={{printer, printerConnected, requestPrinter, printTest, printPassword}}>
            {children}
        </PrintingContext.Provider>
    )
}