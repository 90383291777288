import { useContext, useEffect } from "react";
import styled from "styled-components";
import { DataContext } from "../../../contexts/DataProvider";
import { HexToRgba } from "../../../methods/HexToRgba";

const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    margin: 3rem;
    background-color: ${props => props.background};
    border: ${props => props.stroke};
    border-radius: ${props => props.radius}px;
    font-size: ${props => props.textSize}rem;
    font-weight: ${props => props.textWeight};
    color: ${props => props.textColor};
    cursor: pointer;
`

export default function Button({label, onClick}){

    const { UI } = useContext(DataContext)

    const { width, height, strokeWidth, stroke, strokeColor, radius, bg, buttonColor, buttonOpacity, textSize, textWeight, textColor} = UI

    return (
        <Container
            background={bg ? HexToRgba(buttonColor, buttonOpacity) : 'transparent'}
            height={window.innerHeight * (height/100)}
            width={window.innerWidth * (width/100)}
            radius={window.innerHeight * (height/100) * (radius/100)}
            stroke={stroke ? `${strokeWidth}px solid ${strokeColor}` : 'none'}
            textSize={textSize*0.4}
            textWeight={textWeight*100}
            textColor={textColor}
            onClick={onClick}
        >
            {label}
        </Container>
    )
}