import { useContext, useEffect, useState } from "react";
import Button from "./button";
import { DataContext } from "../../contexts/DataProvider";
import PrintingIndicator from "./printingIndicator";
import { create } from "../../api/queries";
import { PrintingContext } from "../../contexts/PrintingProvider";
import Background from "./background";
import BackButton from "./backButton";


export default function IES(){

    const { token, services, UI } = useContext(DataContext)
    const { printPassword } = useContext(PrintingContext)

    const [screen, setScreen] = useState('password')
    const [selectedService, setSelectedService] = useState(null)

    const [printing, setPrinting] = useState(false)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        if(services) handleMultSubplace()
    },[services])
    
    const handleMultSubplace = () => {
        if(services.length > 1) return setScreen('subplace')
        setSelectedService(services[0].name)
    }

    const handleServiceSelect = (selected) => {
        setSelectedService(selected)
        setScreen('password')
    }

    const handlePasswordSelect = async (password) => {

        setPrinting(true)

        create('calls/create', password, token).then(res => {
            printPassword(res.data.createdCall)
            handleMultSubplace()
        }).catch(err => {
            setShowError(true)
        }
        ).finally(() => {
            setPrinting(false)
        })
    }

    return(
        <Background UI={UI}>
            {
                printing ?
                <PrintingIndicator show={printing}/>
                :
                screen === 'subplace' && services.length > 1 ? 
                services.map((e,i) => {
                    return <Button key={i} label={e.name} UI={UI} onClick={() => handleServiceSelect(e.name)}/>
                })
                :
                screen === 'password' &&
                services.find(service => service.name === selectedService)?.options.map((e,i) => {
                    return <Button key={i} label={e.type} UI={UI} onClick={() => handlePasswordSelect(e)}/>
                })
            }
            {
                screen === 'password'  && services.length > 1 &&
                <BackButton onClick={() => setScreen('subplace')}/>
            }
        </Background>
    )
}